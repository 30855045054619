<template>
  <div class="redpacket">
    <div style="background-color: #fff; text-align: center">
      <div class="red-top">
        <van-icon
          name="arrow-left"
          color="#fff"
          v-show="false"
          style="line-height: 50px; padding: 0px 15px"
        />
      </div>

      <div class="half_circle"></div>

      <div v-if="redpacket.sendUser">
        <div class="red-name">
          <van-image
            fit="cover"
            width="25"
            height="25"
            radius="5"
            :src="
              redpacket.sendUser && redpacket.sendUser.avatar
                ? redpacket.sendUser.avatar
                : require('@/assets/logo.jpg')
            "
            class="avatar"
          />
          <div
            style="
              font-size: 1.2rem;
              display: inline-block;
              margin-left: 5px;
              font-weight: bold;
            "
          >
            {{
              redpacket.sendUser && redpacket.sendUser.nickName
                ? redpacket.sendUser.nickName
                : redpacket.sendUser.code26
            }}{{ $t("的红包") }}
          </div>
        </div>
        <div class="red-desc">{{ $t("一起来分享") }}</div>
      </div>

      <div v-if="openedAmount" style="color: #bca070; padding: 30px 15px">
        <span
          style="font-size: 3.5rem; display: inline-block; margin-right: 5px"
          >{{ openedAmount }}</span
        ><span>{{ $t("铢") }}</span>
        <div @click="toBalance">
          <span style="font-size: 0.9rem">
            {{ $t("已存入您的小蜜蜂账户余额，可直接消费") }} ></span
          >
        </div>
      </div>

      <div v-else style="color: #bca070; padding: 0px 15px 15px 15px">
        {{ $t("您来迟了,红包已被抢光了!") }}
      </div>
    </div>

    <div
      v-if="redpacket.openedList"
      style="background-color: #fff; margin-top: 15px"
    >
      <div
        style="
          color: #999;
          padding: 10px 15px;
          border-bottom: 1px solid #f5f5f5;
        "
      >
        {{ $t("count个红包已被领取", { count: redpacket.openedList.length }) }}
      </div>

      <div
        v-for="item in redpacket.openedList"
        :key="item.id"
        class="item-record"
      >
        <van-image
          width="50"
          height="50"
          radius="5"
          fit="cover"
          :src="
            item.openUser && item.openUser.avatar
              ? item.openUser.avatar
              : require('@/assets/logo.jpg')
          "
        />

        <div
          style="flex: 1; padding: 15px 10px 15px 10px"
          class="van-hairline--bottom"
        >
          <div style="display: flex; font-size: 1.2rem; margin-bottom: 3px">
            <span style="flex: 1">{{
              item.openUser && item.openUser.nickName
                ? item.openUser.nickName
                : item.userCode26
            }}</span>
            <span style="font-size: 1rem"
              >{{ item.amount }} {{ $t("铢") }}</span
            >
          </div>
          <span style="color: #999">{{ formatTime(item.createdAt) }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Icon, Image as VanImage, Toast } from "vant";
import { redPacketOpen, redPacketDetail, fetchUserInfo } from "@/service/api";
import { formatShortTime } from "@/utils/utils";

export default {
  components: {
    [Icon.name]: Icon,
    [VanImage.name]: VanImage,
  },
  data() {
    return {
      redPacketId: this.$route.query.redPacketId,
      openedAmount: 0,
      redpacket: {},
      userInfo: null,
    };
  },
  mounted() {
    this.redPacketOpen();
  },
  methods: {
    formatTime(item) {
      return formatShortTime(item);
    },
    toBalance() {
      this.$router.push({
        name: "balance",
        query: { balance: this.userInfo ? this.userInfo.user.balance : "" },
      });
    },
    async fetchUserInfo() {
      const res = await fetchUserInfo();

      if (res && res.success) {
        this.userInfo = res.data;
        this.redPacketDetail();
      }
    },
    async redPacketOpen() {
      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: this.$t("..."),
      });

      const res = await redPacketOpen(this.redPacketId);
      if (res.success) {
        this.openedAmount = res.openedAmount;
        Toast.success(this.$t("领取成功..."));
      } else Toast.fail(res.message);

      this.fetchUserInfo();
    },
    async redPacketDetail() {
      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: this.$t("加载中..."),
      });

      const res = await redPacketDetail(this.redPacketId);
      if (res.success) {
        const { data } = res;
        this.redpacket = data;

        const currentUserCode = this.userInfo.user.code;
        const myOpenedRecord = data.openedList.find(
          (item) => item.userCode == currentUserCode
        );
        const openedAmount = myOpenedRecord ? myOpenedRecord.amount : 0;
        this.openedAmount = openedAmount;
      }
      Toast.clear();
    },
  },
};
</script>
<style lang="less" scoped>
@bgcolor: #f05a49;

.redpacket {
}

.red-top {
  background-color: @bgcolor;
  height: 50px;
  text-align: left;
  z-index: 2;
  width: 100%;
  position: fixed;
  top: 0px;
}

.red-name {
  display: inline-flex;
  align-items: center;
  margin-top: 20px;
  padding: 30px 0px 10px 0px;
}

.red-desc {
  color: #999;
  font-size: 0.8rem;
}

.item-record {
  display: flex;
  align-items: center;
  padding: 0px 15px;
}

.half_circle {
  position: relative;
  width: 100%;
  height: 50px;
  text-align: center;
  color: #fff;
  background-color: @bgcolor;
}
.half_circle:after {
  position: absolute;
  left: 0;
  bottom: -20px;
  z-index: 1;
  content: "";
  height: 40px;
  width: 100%;
  border-radius: 0 0 50% 50%;
  background-color: @bgcolor;
}
</style>